<template>
  <div class="">
    <div class="col-12">
      <div class="card">
        <slot name="start">
          <Toolbar class="tw-mb-8 tw-p-3">
            <template #start>
              <div>
                <Button
                  label="Tambah"
                  icon="pi pi-plus"
                  class="p-button-sm p-button-success tw-bg-blue-light"
                  @click="navigateToCreate"
                />
              </div>
            </template>
          </Toolbar>
        </slot>
        <div class="text-xl font-medium text-black mb-3">
          <p>Salon</p>
        </div>
        <DataTable
          :value="salon"
          :paginator="true"
          :rows="10"
          dataKey="id"
          :rowHover="true"
          v-model:filters="filters1"
          filterDisplay="menu"
          :filters="filters1"
          responsiveLayout="scroll"
          :globalFilterFields="[
            'no',
            'name',
            'address',
            'coverage_area',
            'provinces_name',
            'cities_name',
            'districts_name',
            'status_name',
          ]"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="perPageOptions"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        >
          <template #header>
            <div class="flex justify-content-between flex-column sm:flex-row">
              <Button
                type="button"
                icon="pi pi-filter-slash"
                label="Hapus Filter"
                class="p-button-outlined mb-2"
                @click="clearFilter()"
              />
              <span class="p-input-icon-left mb-2">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters1['global'].value"
                  placeholder="Kata kunci"
                  style="width: 100%"
                />
              </span>
            </div>
          </template>
          <template #empty>
            <div
              class="
                tw-flex
                tw-justify-center
                tw-items-center
                tw-py-20
                tw-text-2xl
                tw-font-bold
              "
            >
              Tidak ada data yang ditemukan
            </div>
          </template>
          <template #loading>
            <div class="tw-flex tw-justify-center tw-items-center tw-py-20">
              Loading records, please wait...
            </div>
          </template>
          <Column field="no" header="No." style="min-width: 2rem">
            <template #body="{ data }">
              {{ data.no }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Search by number"
              />
            </template>
          </Column>
          <Column header="Nama" filterField="name" style="min-width: 12rem">
            <template #body="{ data }">
              {{ data.name }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Search by name"
              />
            </template>
          </Column>
          <Column
            header="Alamat"
            filterField="address"
            style="min-width: 12rem"
          >
            <template #body="{ data }">
              {{ data.address ? data.address : `-` }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Search by address"
              />
            </template>
          </Column>
          <!-- <Column
            header="Coverage Area"
            filterField="coverage_area"
            style="min-width: 12rem"
          >
            <template #body="{ data }">
              {{ `${data.coverage_area} KM` }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Search by number"
              />
            </template>
          </Column> -->
          <Column
            header="Provinsi"
            filterField="provinces_name"
            style="min-width: 12rem"
          >
            <template #body="{ data }">
              {{ data.provinces_name }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Search by province name"
              />
            </template>
          </Column>
          <Column
            header="Kota"
            filterField="cities_name"
            style="min-width: 12rem"
          >
            <template #body="{ data }">
              {{ data.cities_name }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Search by city name"
              />
            </template>
          </Column>
          <Column
            header="Kecamatan"
            filterField="districts_name"
            style="min-width: 12rem"
          >
            <template #body="{ data }">
              {{ data.districts_name }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Search by districts name"
              />
            </template>
          </Column>
          <Column
            header="Status"
            filterField="status_name"
            style="min-width: 6rem"
          >
            <template #body="{ data }">
              {{ data.status_name}}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Search by status name"
              />
            </template>
          </Column>
          <Column
            header="Aksi"
            :showFilterMatchModes="false"
            :filterMenuStyle="{ width: '14rem' }"
            style="min-width: 14rem"
          >
            <template #body="{ data }">
              <Button
                icon="pi pi-eye"
                class="p-button-rounded p-button-info"
                @click="detailData(data.id)"
              />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from "primevue/api";
import SalonService from "@/service/SalonService";
export default {
  props: {
    perPageOptions: {
      type: Array,
      required: false,
      default() {
        return [10, 20, 50];
      },
    },
  },
  data() {
    return {
      salon: [],
      filters1: null,
    };
  },
  SalonService: null,
  created() {
    this.SalonService = new SalonService();
    this.initFilters();
  },
  mounted() {
    this.loadData();
  },
  methods: {
    navigateToCreate() {
      this.$router.push({ name: "create-salon" });
    },
    detailData(id) {
      this.$router.push({ name: "detail-salon", params: { id: id } });
    },
    loadData() {
      this.SalonService.getAll().then((response) => {
        if (response.code === 200) {
          this.salon = response.data.salons;
          const temp = [];
          this.salon.forEach((salons, idx) => {
            temp.push({ ...salons, no: idx + 1 });
          });
          this.salon = temp;
        }
      });
    },
    initFilters() {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        no: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        address: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        coverage_area: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        provinces_name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        cities_name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        districts_name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
         status_name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      };
    },
    clearFilter() {
      this.initFilters();
    },
  },
};
</script>

<style scoped lang="scss">
</style>
